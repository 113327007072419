import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import isString from 'lodash/isString';
import { m, AnimatePresence } from 'framer-motion';
// @mui
import { alpha } from '@mui/material/styles';
import {
  List,
  Stack,
  Button,
  IconButton,
  ListItemText,
  ListItem,
  Box,
  LinearProgress,
  Typography,
  OutlinedInput,
  Tooltip,
  Popover,
  Alert,
  // TextField
} from '@mui/material';import 

// @mui icons
ArrowForwardTwoToneIcon from '@mui/icons-material/ArrowForwardTwoTone';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import { resetCurrentVideo } from 'src/redux/slices/videos';
import { useDispatch } from 'src/redux/store';
// utils

//
// import Image from '../Image';
import Iconify from '../Iconify';
import { varFade } from '../animate';


// ----------------------------------------------------------------------

const getFileData = (file) => {
  if (typeof file === 'string') {
    return {
      key: file,
    };
  }
  return {
    key: file.name,
    name: file.name,
    size: file.size,
    preview: file.preview,
  };
};

// ----------------------------------------------------------------------

MultiFilePreview.propTypes = {
  files: PropTypes.array,
  fileStatus: PropTypes.any,
  progress: PropTypes.array,
  showPreview: PropTypes.bool,
  onRemove: PropTypes.func,
  onRemoveAll: PropTypes.func,
  fileName: PropTypes.array,
  handleOnChangeFileName: PropTypes.func,
  isApplet: PropTypes.bool,
  handleDisableAccelerateAndRetry: PropTypes.func
};

export default function MultiFilePreview({
  showPreview = false,
  fileStatus,
  files,
  onRemove,
  onRemoveAll,
  progress,
  handleOnChangeFileName,
  isApplet,
  handleDisableAccelerateAndRetry
}) {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const hasFile = files.length > 0;
  const dispatch=useDispatch();
  const handleEditClick = (videoId) => {
    dispatch(resetCurrentVideo())
    navigate(`/video/${videoId}`, { replace: true });
  };

  const open = Boolean(anchorEl);

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <List disablePadding sx={{ ...(hasFile && { my: 3, p: 1, m: 0 }) }}>
        <AnimatePresence>
          {files.map((file, index) => {
            const { key, name, preview } = getFileData(file);

            if (showPreview) {
              return (
                <ListItem
                  key={key}
                  component={m.div}
                  {...varFade().inRight}
                  sx={{
                    p: 0,
                    m: 0.5,
                    width: 80,
                    height: 80,
                    borderRadius: 1.25,
                    overflow: 'hidden',
                    position: 'relative',
                    display: 'inline-flex',
                    border: (theme) => `solid 1px ${theme.palette.divider}`,
                    background: 'white',
                  }}
                >
                  {/* <Image alt="preview" src={isString(file) ? file : preview} ratio="1/1" /> */}
                  {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
                  <video cf-video-load="file" src={isString(file) ? file : preview} style={{ width: 80, height: 80 }} />
                  <IconButton
                    size="small"
                    onClick={() => onRemove(file, index)}
                    sx={{
                      top: 6,
                      p: '2px',
                      right: 6,
                      position: 'absolute',
                      color: 'common.white',
                      bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72),
                      '&:hover': {
                        bgcolor: (theme) => alpha(theme.palette.grey[900], 0.48),
                      },
                    }}
                  >
                    <Iconify icon={'eva:close-fill'} />
                  </IconButton>
                </ListItem>
              );
            }

            return (
              <ListItem
                key={key}
                component={m.div}
                {...varFade().inRight}
                sx={{
                  my: 1,
                  px: 2,
                  py: 0.75,
                  borderRadius: 0.75,
                  background: 'white',
                  border: (theme) => `solid 1px ${theme.palette.divider}`,
                }}
              >
                <Iconify icon={'eva:file-fill'} sx={{ width: 28, height: 28, color: 'text.secondary', mr: 2 }} />
                <ListItemText
                  primary={
                    <OutlinedInput sx={{width:'95%'}}
                      disabled={fileStatus && fileStatus.toLowerCase() !== "ready"}
                      defaultValue={isString(file) ? file : name}
                      onChange={(e) => handleOnChangeFileName(e, index, name)}
                      inputProps={{ sx: { p: '5px 8px' } }}
                      autoFocus
                      onFocus={(e) => e.target.select()}
                    />
                    // <TextField
                    //   variant="standard"
                    //   defaultValue={isString(file) ? file : name}
                    //   onChange={(e) => handleOnChangeFileName(e, index, name)}
                    //   InputProps={{ disableUnderline: true }}
                    //   autoFocus
                    //   onFocus={(e) => e.target.select()}
                    // />
                  }
                  primaryTypographyProps={{ variant: 'subtitle2', noWrap: true, maxWidth: '100%' }}
                  secondaryTypographyProps={{ variant: 'caption' }}
                />
                {progress.map(
                  (item, index) =>
                    item.fileName === key && (
                      <React.Fragment key={index}>
                        <Box sx={{ minWidth: 300 }}>
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Box sx={{ width: '100%', mr: 1 }}>
                              {(item.percentage === 0 && (item.status === 'Ready' || item.status === 'Uploading...') && fileStatus === 'processing') && (
                                <LinearProgress />
                              )}
                              {((item.percentage > 0 && item.percentage < 100) ||(item.status === 'Ready' && fileStatus !== 'processing')) && (
                                <LinearProgress variant="determinate" value={item.percentage} />
                              )}
                              {item.percentage === 100 && item.status === 'Processing...' && (
                                <LinearProgress value={item.percentage} />
                              )}
                            </Box>
                            <Box sx={{ minWidth: 35, width: item.status === 'S3 Upload Error' ? '100%' : 'unset', display: 'flex' }}>
                              {item.percentage > 0 && item.percentage < 100 ? (
                                <Typography variant="body2" color="text.secondary">{`${item.percentage}%`}</Typography>
                              ) : ( <>
                                <Typography className={item.status === 'Completed' && 'Completed'} variant="body2" color={item.status === "Completed"?"green":"text.secondary"} display='flex' alignItems='center'>
                                  {item.percentage === 100 && item.status === 'Ready' ? 'Processing...' : item.status}
                                </Typography>
                                {item.status === 'S3 Upload Error' && <>
                                  <Tooltip title="Learn More">
                                    <IconButton
                                      aria-label='S3UploadError'
                                      size='medium'
                                      onClick={handleOpenMenu}
                                    >
                                      <InfoOutlinedIcon />
                                    </IconButton>
                                  </Tooltip>
                                  <Popover
                                    open={open}
                                    anchorEl={anchorEl}
                                    onClose={handleCloseMenu}
                                    anchorOrigin={{
                                      vertical: 'bottom',
                                      horizontal: 'left',
                                    }}
                                    sx={{p:0, m:0}}
                                  >
                                    <Stack sx={{ p: 1, minWidth: 300 }}>
                                      <Alert severity="info" 
                                        sx={{width:'100%',
                                          '& .MuiAlert-message':{
                                            width:'100%'
                                          },
                                          '& .MuiAlert-icon': {
                                            alignItems:'center'
                                          }
                                        }}
                                      >
                                        <Stack flexDirection='row' sx={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                                          <Box>
                                            <Typography>Recommending to disable accelerated uploads</Typography>
                                          </Box>
                                          <Box>
                                            <Button sx={{ mr: 1, ml: 1 }} variant='outlined' 
                                              onClick={() => {handleDisableAccelerateAndRetry()}} 
                                            > Disable and Retry </Button>
                                          </Box>
                                        </Stack>
                                      </Alert>
                                    </Stack>
                                  </Popover>
                                </>}
                              </>)}
                            </Box>
                          </Box>
                        </Box>
                        {item.status !== 'Completed' ? (
                          <IconButton edge="end" size="small" onClick={() => onRemove(file, index)} sx={{ ml: 2 }}>
                            <Iconify icon={'eva:close-fill'} />
                          </IconButton>
                        ) : (
                          <IconButton
                            disabled={isApplet}
                            edge="end"
                            size="small"
                            onClick={() => handleEditClick(item.videoId)}
                            sx={{ 
                              ml: 2.5,
                              background: (theme) => theme.palette.warning.main,
                              color: (theme) => theme.palette.warning.contrastText,
                              "&:hover": {
                                background: (theme) => theme.palette.warning.main,
                              }
                            }}
                          >
                            <ArrowForwardTwoToneIcon fontSize='8px' />
                          </IconButton>
                        )}
                      </React.Fragment>
                    )
                )}
              </ListItem>
            );
          })}
        </AnimatePresence>
      </List>

      {hasFile && (
        <Stack direction="row" justifyContent="flex-end" spacing={1.5} sx={{ p: 2 }}>
          <Button color="inherit" size="small" onClick={onRemoveAll}>
            Remove all
          </Button>
          {/* <Button size="small" variant="contained">
            Upload files
          </Button> */}
        </Stack>
      )}
    </>
  );
}
