import { createSlice } from '@reduxjs/toolkit';

import * as moment from 'moment'
import { HOST_API } from 'src/config';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  galleries: [],
  currentGallery: null,
  createdGalleryId: null,
  availablePrices:[],
  galleryCodes:[],
  galleryPayments:[],
  addUpdateGalleryCode:false,
  galleryMetrics:{},
  galleryViews:{},
  galleryResponses: []
};

const slice = createSlice({
  name: 'playlists',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET CREATED ID
    getCreatedGalleryId(state, action) {
      state.isLoading = false;
      state.createdGalleryId = action.payload;
      // console.log(state.createdPlaylistId, 'PUT-PLAYLIST-ID-IN-STATE')
    },

    // GET GALLERIES
    getGalleriesSuccess(state, action) {
      state.isLoading = false;
      state.galleries = action.payload.map(value => {
        value.settings = JSON.parse(value.settings);
        value.content = JSON.parse(value.content);
        value.numberof = 0;
        value.content.map(section => {
          value.numberof += section.videos.length;
          return section
        })
        if(value.settings.background && value.settings.background.indexOf('mp4')>-1){
          value.settings.backgroundVideo = value.settings.background;
        }

        if(!value.settings.background || value.settings.background === '' ) {
          value.settings.background = value.content[0]?.videos[0] ? `${HOST_API}/video/image/?id=${ value.content[0].videos[0].id}` : "https://zone-assets-api.vercel.app/assets/img_placeholder.svg"
        }
        value.encodedId  = btoa(value.id);
        return value;
      });

    },

    // GET CURRENT_GALLERY
    getCurrentGallerySuccess(state, action) {
      state.isLoading = false;
      const gallery = action.payload;
      const content = JSON.parse(gallery.content);
      
      if(!content[0].type && content[0].videos?.length === 0)
        content[0].type='video'

      const settings = JSON.parse(gallery.settings);
      const accessChangeDisabled = gallery.access !== 'free' && gallery.name.indexOf('clone') === -1;
      state.currentGallery = { ...gallery, content, settings,accessChangeDisabled };
    },
    getPricesSuccess(state, action){
      state.isLoading = false;
      state.availablePrices = action.payload;
    },
    getGalleryPaymentsSuccess(state,action){
      state.isLoading = false;
      state.galleryPayments = action.payload;
    },
    getGalleryCodesSuccess(state,action){
      state.isLoading = false;
      let codes =  action.payload
      const now = moment();
      codes = codes.map((code) =>{
          if(code.expires){
            const expires = moment(code.expires);
            if(now > expires){
              code.expired = true;
            }
          }
          return code
      })
      state.galleryCodes = codes;
    },
    // addUpdateGalleryCodesSuccess(state,action){
    //   state.isLoading = false;
    //   state.addUpdateGalleryCode = true;
    // }
    getMetricsSuccess(state, action){
      state.isLoading = false;
      state.galleryMetrics = action.payload;
    },
    getViewsSuccess(state, action){
      state.isLoading = false;
      state.galleryViews = action.payload;

    },
    resetCurrentGallerySuccess(state){
      state.isLoading = false
      state.currentGallery = null
    },
    // GET GALLERY VIEWS
    getGalleryResponses(state, action) {
      state.isLoading = false
      state.galleryResponses = action.payload
    },
  },
});

// Reducer
export default slice.reducer;

export const { actions } = slice;

// ----------------------------------------------------------------------

export function getGalleries(token,groups='', isGetForStudentProfile = false) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      let response = ''
      if(!isGetForStudentProfile)
        response = await axios.get(`/spotlight?Token=${token}&videoGroup=${groups}`);
      else
        response = await axios.get(`/spotlight?Token=${token}`);
      dispatch(slice.actions.getGalleriesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getGallery(token, galleryId) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/spotlight?id=${galleryId}&Token=${token}`);
      dispatch(slice.actions.getCurrentGallerySuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function getMetrics(token, galleryId,dateParams={}) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/spotlight/metrics?spotlightID=${galleryId}&Token=${token}`,{ params: dateParams });
      dispatch(slice.actions.getMetricsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export async function getViewsEndPoint(token, galleryId,mode, id,dateParams={},page = 1){
  let query = '';
  if(mode === 'code'){
    query = `&code=${id.code}`
    if(id.email){
      query = `${query}&email=${id.email}`
    }
  }
  if(mode === 'payment')  query = `&paymentID=${id}`
  const response = await axios.get(`/spotlight/views?paginate=true&spotlightID=${galleryId}&page=${page}&Token=${token}${query}`,{ params: dateParams });
  return response
}
export function getViews(token, galleryId,mode,id,dateParams={},page = 1) {

  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await getViewsEndPoint(token, galleryId,mode, id,dateParams,page);
      dispatch(slice.actions.getViewsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getGalleryViewsByViewerId(token, customViewerID) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/spotlight/views?&Token=${token}&email=${customViewerID}`);     
      dispatch(slice.actions.getGalleryResponses(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

/* eslint-disable consistent-return */
export function createGallery(token, name, returnValue,groupId = null, feature) {
    const spotlightContent = [{
      name: 'First Section',
      videos: [],
      type: 'video'
    }];
    const layout = feature === 'course' ? 'course' : 'sidebar';
    const description = feature === 'course' ? 'Course Description' : 'Gallery Description';
    
    const spotlightSettings = {
        trailer: false,
        trailerVideo: {},
        layout,
        theme: 'dark',
        headerAlignment: 'left',
        description,
        background: 'https://images.snapwi.re/3be8/563d82a96c2c051a117b23c6.w800.jpg',
        galleryThumbnail: '',
        commingNext: true,
        allowSkipping: true,
        videoDisplay:'videotimestamp'
    };
  let data = `Token=${token}&name=${encodeURIComponent(name)}&content=${JSON.stringify(spotlightContent).replace( /&/g, "%26")}&settings=${JSON.stringify(spotlightSettings).replace( /&/g, "%26")}&access=free`;
  if(groupId){
    data = `${data}&videoGroup=${groupId}`
  }
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`/spotlight`, data);
      dispatch(slice.actions.getCreatedGalleryId(response.data));
      if( returnValue ) return response.data
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
/* eslint-disable consistent-return */
export function updateGallery(gallery,user,groups=null) {
  // const data = `Token=${user.token}&id=${gallery.id}&name=${encodeURIComponent(gallery.name)}&content=${JSON.stringify(gallery.content).replace( /&/g, "%26")}&settings=${JSON.stringify(gallery.settings).replace( /&/g, "%26")}&access=${gallery.access}`;
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      // await axios.put(`/spotlight`, data);
      await updateGalleryEndPoint(gallery,user,groups)
      dispatch(getGallery(user.token, gallery.id));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getPrices(user) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/spotlight/getSubscriptions?stripeAT=${user.stripeAT}`);
      dispatch(slice.actions.getPricesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export async function oauthStripe() {
  const urlBase = 'https://connect.stripe.com/oauth/authorize?response_type=code&redirect_uri=https://projects.spotlightr.com/settings/integrations&scope=read_write&client_id='
  const response = await axios.get(`/connect/clientID`);
  window.open(`${urlBase}${response.data}`, '_blank');
};

export function getPayments(gallery,user) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/spotlight/payments?spotlightID=${gallery.id}&Token=${user.token}`);
      dispatch(slice.actions.getGalleryPaymentsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
};

export function getCodes(gallery,user) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/spotlight/codes?spotlightID=${gallery.id}&Token=${user.token}`);
      dispatch(slice.actions.getGalleryCodesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
};

export async function createCodes(id,user,formatedExpireDate,never,codeValues,codes, quantity) {

    const response = await axios.post(`/spotlight/codes?id=${id}&Token=${user.token}&userID=${user.id}&quantity=${quantity}&unlocks=${codes.unlockTimes}&expires=${formatedExpireDate}&time=${codes.time}&never=${never}&codeValues=${codeValues}&deviceLimit=${codes.deviceLimit}`);
    return response

};
export async function createStudent(id,user,never,student) {
  const response = await axios.post(`/spotlight/codes?id=${id}&Token=${user.token}&userID=${user.id}&quantity=${student.quantity}&unlocks=${student.unlockTimes}&expires=${student.formatedExpireDate}&time=${student.time}&never=${never}&codeValues=${student.codeValues}&deviceLimit=${student.deviceLimit}&email=${student.email}&password=${student.password}`);
  return response

};
export async function createAccess(id,user,access) {

  const response = await axios.get(`/spotlight/createAccess?id=${id}&Token=${user.token}&email=${access.email}&password=${encodeURIComponent(access.password)}&deviceLimit=${access.deviceLimit}`);
  return response

};
export async function updateCode(user,code) {
  if(!code.expireNever){
    code.expireNever = 0;
  }
  const response = await axios.post(`/spotlight/updateCode?id=${code.id}&Token=${user.token}&unlocks=${code.unlocks}&expire=${code.expireNever}&devices=${code.devices}&deviceLimit=${code.deviceLimit}`);
  return response
};
export async function updateAccess(user,payment) {
  const response = await axios.post(`/spotlight/updatePayment?id=${payment.id}&Token=${user.token}&status=${payment.status}&password=${payment.password}&devices=${payment.devices}&deviceLimit=${payment.deviceLimit}`);
  return response
};
export async function deleteGalleries(user, selectedGalleries){
  const data = `token=${user.token}&flag=spotlight&id=${selectedGalleries}`;
  const response =  await axios.post('/deleteMachine',data);
  return response
}
export async function deleteAccessCode(user, selectedCodes){
  const data = `token=${user.token}&flag=codes&id=${selectedCodes}`;
  const response =  await axios.post('/deleteMachine',data);
  return response
}
export function resetCurrentGallery() {
  return async () => {
    dispatch(slice.actions.resetCurrentGallerySuccess())
  }
}
export async function cloneGalleries(user,id,type) {
  const response =  await axios.post(`/clone?Token=${user.token}&id=${id}&table=${type}`);
  return response
};
export async function updateGalleryEndPoint(gallery,user,groups) {
  let data = `Token=${user.token}&id=${gallery.id}&name=${encodeURIComponent(gallery.name)}&content=${JSON.stringify(gallery.content).replace( /&/g, "%26")}&settings=${JSON.stringify(gallery.settings).replace( /&/g, "%26")}&access=${gallery.access}&tags=${typeof gallery.tags !== "string" ? JSON.stringify(gallery.tags) : gallery.tags}`;
  if(groups)
    data = `${data}&videoGroup=${groups}`;
  const response = await axios.put(`/spotlight`, data);
  return response
};
