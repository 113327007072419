import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';

// layouts
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';

// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';

// components
import LoadingScreen from '../components/LoadingScreen';
import SwitchToYearlyDialog from '../components/SwitchToYearlyDialog';


// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          ),
        },
        { path: 'login-unprotected', element: <Login /> },
        { path: 'register-unprotected', element: <Register /> },
        { path: 'forgot', element: <ResetPassword /> },
        {
          path: 'inactive',
          element: (
              <InActive />
          ),
        }
      ]
    },
    {
      path: 'adminuser/:token',
      element: (
        <GuestGuard>
          <AdminUser />
        </GuestGuard>
      ),
    },
    {
      path: 'applet',
      element: (
        <GuestGuard>
          <Applet />
        </GuestGuard>
      ),
    },
    {
      path: 'report',
      element: (
        // <GuestGuard>
          <AudienceViewerProfile />
        // </GuestGuard>
      ),
    },
    {
      path: 'admindashboard',
      element: (
        // <GuestGuard>
         <AdminDashboard/>
        // </GuestGuard>
      ),
    },
    {
      path: 'debug',
      element: (
        // <GuestGuard>
        <Debug/>
        // </GuestGuard>
      ),
    },
    {
      path: 'certificate',
      element: (
        <Certificate/>
      ),
    },
    {
      path: '/',
      element: (
        <AuthGuard>
            <DashboardLayout />
            <SwitchToYearlyDialog/>          
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to="/videos" replace />, index: true },
        { path: 'authhubspot', element: <HubspotCode />},
        { path:'videos/:groupId',element: <Videos />},
        { path: 'videos', element: <Videos /> },
        // { path: 'projects/:groupId/:tab', element: <ProjectDashboard /> },
        {
          path: 'projects/:groupId',
          element: <ProjectDashboard />,
          children: [
            { element: <Navigate to="/projects/:groupId/videos" replace />, index: true },
            { path: 'videos', element: <ProjectDashboard /> },
            { path: 'playlists', element: <ProjectDashboard /> },
            { path: 'galleries', element: <ProjectDashboard /> },
            { path: 'courses', element: <ProjectDashboard /> },
            { path: 'quizzes', element: <ProjectDashboard /> },
          ],
        },
        {
          path: 'video/:encodedId',
          element: <EditVideo />,
          children: [
            { element: <Navigate to="/video/:encodedId/player" replace />, index: true },
            { path: 'player', element: <EditVideo /> },
            { path: 'settings', element: <EditVideo /> },
            { path: 'analytics', element: <EditVideo /> },
            { path: 'quiz-result', element: <EditVideo /> },
          ],
        },
        { path: 'create/:encodedGroupId', element: <CreateVideo /> },
        { path: 'audience', element: <MyAudience /> },
        { path: 'reports', element: <Reports /> },
        { path: 'comment', element: <CommentReports /> },
        { path: 'quizzes', element: <Quizzes /> },
        { path: 'quiz/:encodedId', element: <EditQuiz /> },
        { path: 'galleries', element: <Galleries /> },
        { path: 'gallery/:encodedId', element: <EditGallery /> },
        { path: 'courses', element: <Galleries /> },
        { path: 'course/:encodedId', element: <EditGallery /> },
        { path: 'playlists', element: <Playlists /> },
        { path: 'playlist/:encodedId', element: <EditPlaylists /> },
        {
          path: 'account',
          element: <Billing />,
          children: [
            { element: <Navigate to="/account/profile" replace />, index: true },
            { path: 'profile', element: <Billing /> },
            { path: 'license', element: <Billing /> },
            { path: 'team-users', element: <Billing /> },
            { path: 'affiliate', element: <Billing /> },
            { path: 'billing-info', element: <Billing /> },
          ],
        },
        {
          path: 'settings',
          element: <Settings />,
          children: [
            { element: <Navigate to="/settings/global-options" replace />, index: true },
            { path: 'global-options', element: <Settings /> },
            { path: 'translations', element: <Settings /> },
            { path: 'integrations', element: <Settings /> },
            { path: 'themes', element: <Settings /> },
            { path: 'apply-player-settings', element: <Settings /> },
            { path: 'allow-domains', element: <Settings /> },
            { path: 'files', element: <Settings /> },
          ],
        },{
          path: 'ideas',
          element: (<IdeasBoard />)
        },
        {
          path: 'settings-account',
          children: [
            { element: <Navigate to="/settings-account/profile" replace />, index: true },
            { path: 'profile', element: <PageFive /> },
            { path: 'billing', element: <PageFive /> },
          ],
        },
        
      ],
    },
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
    
  ]);
}

// Authentication
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const Register = Loadable(lazy(() => import('../pages/auth/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
const InActive = Loadable(lazy(() => import('../pages/auth/InActive')));
const AdminUser = Loadable(lazy(() => import('../pages/auth/AdminUser')));
const Applet = Loadable(lazy(() => import('../pages/auth/Applet')));
const AudienceViewerProfile = Loadable(lazy(() => import('../pages/auth/AudienceViewerProfile')));

// Dashboard
const Videos = Loadable(lazy(() => import('../pages/dashboard/videos')));
const AdminDashboard = Loadable(lazy(() => import('../pages/dashboard/admin')));
const EditVideo = Loadable(lazy(() => import('../pages/dashboard/videos/EditVideo')));
const CreateVideo = Loadable(lazy(() => import('../pages/dashboard/videos/CreateVideo')));
const MyAudience = Loadable(lazy(() => import('../pages/dashboard/analytics/MyAudience')));
const Reports = Loadable(lazy(() => import('../pages/dashboard/analytics/Reports')));
const CommentReports = Loadable(lazy(() => import('../pages/dashboard/analytics/CommentReports')));
const Quizzes = Loadable(lazy(() => import('../pages/dashboard/quizzes')));
const EditQuiz = Loadable(lazy(() => import('../pages/dashboard/quizzes/EditQuiz')));
const Galleries = Loadable(lazy(() => import('../pages/dashboard/galleries')));
const PageFive = Loadable(lazy(() => import('../pages/PageFive')));
const IdeasBoard = Loadable(lazy(() => import('../pages/IdeasBoard')));
const Debug = Loadable(lazy(() => import('../pages/Debug')));
const Certificate = Loadable(lazy(() => import('../pages/Certificate')));
const Playlists = Loadable(lazy(() => import('../pages/dashboard/playlists')));
const EditPlaylists = Loadable(lazy(() => import('../pages/dashboard/playlists/EditPlaylist')));
const EditGallery = Loadable(lazy(() => import('../pages/dashboard/galleries/EditGallery')));
const Settings = Loadable(lazy(() => import('../pages/dashboard/Settings')));
const Billing = Loadable(lazy(() => import('../pages/dashboard/Billing')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
const ProjectDashboard = Loadable(lazy(() => import('../pages/dashboard/projectdashboard')));
const HubspotCode =  Loadable(lazy(() => import('src/components/HubspotCode')));
