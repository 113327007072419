import { useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Stack } from '@mui/material';
// hooks
import { checkExists, StreamActiveStatus } from 'src/utils/common';
import { useSelector } from 'src/redux/store';

import useSettings from '../../hooks/useSettings';
import useResponsive from '../../hooks/useResponsive';
import useCollapseDrawer from '../../hooks/useCollapseDrawer';
import useAuth from '../../hooks/useAuth';
// import useLocalStorage from '../../hooks/useLocalStorage';
// config
import { HEADER, NAVBAR } from '../../config';
//
import DashboardHeader from './header';
import NavbarVertical from './navbar/NavbarVertical';
import NavbarHorizontal from './navbar/NavbarHorizontal';


// ----------------------------------------------------------------------
const shouldForwardPropList = ['collapseClick','isCollapseSearchBar', 'noPaddingHorizontal', 'videoStreamStatus']
const MainStyle = styled('main', {
  shouldForwardProp: (prop) => shouldForwardPropList.indexOf(prop) < 0,
})(({ collapseClick, isCollapseSearchBar, theme, noPaddingHorizontal,videoStreamStatus }) => ({
  flexGrow: 1,
  [theme.breakpoints.up('lg')]: {
    paddingLeft: (noPaddingHorizontal || videoStreamStatus === StreamActiveStatus) ? 0 : 16,
    paddingRight: (noPaddingHorizontal || videoStreamStatus === StreamActiveStatus)  ? 0 : 16,
    paddingTop: isCollapseSearchBar ? HEADER.DASHBOARD_DESKTOP_HEIGHT : 24,
    paddingBottom: 24,
    width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH}px)`,
    transition: theme.transitions.create(['margin-left', 'padding-top'], {
      duration: theme.transitions.duration.standard,
    }),
    ...(collapseClick && {
      marginLeft: videoStreamStatus === StreamActiveStatus? 0 : NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
    }),
  },
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const { isCollapse, collapseClick, isCollapseSearchBar } = useCollapseDrawer();
  const { videoStreamStatus } = useSelector((state) => state.videos);
  // const [ welcomePopUp ] = useLocalStorage('welcomeVideo');

  const { themeLayout } = useSettings();

  const { user, isApplet } = useAuth();
  const { pathname } = useLocation();

  const isDesktop = useResponsive('up', 'lg');

  const [open, setOpen] = useState(false);

  const verticalLayout = themeLayout === 'vertical';

  const restrictedRoutes = () => {
    const modulesRoutes = ['/quizzes', '/galleries', '/playlists', '/courses']
    const analyticsRoutes = ['/audience','reports']
    
    if(checkExists(['SPARK','FREE','LIGHT'],user?.license))
      return !['course','gallery'].some(route => pathname.indexOf(route) > -1 );

    if(user.restricted && !user.teamUser.permissions.modules) 
    return !modulesRoutes.some(route => pathname.indexOf(route) > -1 );

    if(user.restricted && !user.teamUser.permissions.analytics) 
    return !analyticsRoutes.some(route =>  pathname.indexOf(route) > -1);    
  
    return true
  } 

  if (verticalLayout) {
    return (
      <>
        <DashboardHeader onOpenSidebar={() => setOpen(true)} verticalLayout={verticalLayout} />

        {isDesktop ? (
          <NavbarHorizontal />
        ) : (
          <NavbarVertical isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />
        )}

        <Box
          component="main"
          sx={{
            px: { lg: 2 },
            pt: {
              xs: `${HEADER.MOBILE_HEIGHT + 24}px`,
              lg: `${HEADER.DASHBOARD_DESKTOP_HEIGHT + 80}px`,
            },
            pb: {
              xs: `${HEADER.MOBILE_HEIGHT + 24}px`,
              lg: `${HEADER.DASHBOARD_DESKTOP_HEIGHT + 24}px`,
            },
          }}
        >
          {restrictedRoutes() && <Outlet />}
        </Box>
      </>
    );
  }

  return (
    <Box
      sx={{
        display: { lg: 'flex' },
        minHeight: { lg: 1 },
      }}
    >
      {!isApplet && <DashboardHeader isCollapse={isCollapse} onOpenSidebar={() => setOpen(true)} /> }
      {!isApplet && <NavbarVertical isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} /> }

      <MainStyle noPaddingHorizontal={pathname === '/ideas' } collapseClick={collapseClick} videoStreamStatus={videoStreamStatus} isCollapseSearchBar={isCollapseSearchBar}>
        {restrictedRoutes() && <Stack sx={{mt: !isDesktop ? 6 : 'unset'}}><Outlet /></Stack>}
      </MainStyle>
    </Box>
  );
}
